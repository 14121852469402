import { Controller } from '@hotwired/stimulus'
import * as bootstrap from 'bootstrap'

export default class extends Controller {
  connect() {
    this.modalElement = new bootstrap.Modal(this.element, {})
    this.modalElement.toggle()
    this.setEventListeners()
  }

  disconnect() {
    let that = this
    this.element.removeEventListener('hidden.bs.modal', function (event) {
      that.dismissModal()
    })
  }

  setEventListeners() {
    let that = this
    this.element.addEventListener('hidden.bs.modal', function (event) {
      that.dismissModal()
    })
  }

  hide() {
    this.modalElement.hide()
  }

  dismissModal() {
    this.modalElement.dispose()
    this.element.closest('turbo-frame').src = undefined
    this.element.remove();
  }
}