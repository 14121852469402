import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ 'icon' ]

  connect() {
    if (this.hasIconTarget) { this.startPolling() }
  }

  disconnect() {
    this.stopPolling();
  }

  startPolling() {
    if (this.polling) { return }
    this.polling = setInterval(this.checkStatus.bind(this), 3000);
  }

  stopPolling() {
    clearInterval(this.polling)
  }

  checkStatus() {
    fetch(this.buildUrl(), {
      method: 'GET',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
    .then(response => {
      switch (response.status) {
        case 200:
          this.stopPolling()

          if (this.data.get('template') == null) {
            location.reload()
          } else {
            response.text().then(text => {
              $('#' + this.element.id).replaceWith(text)
            })
          }

          break
        case 204:
          // console.log('Still scraping - do nothing;')
          break
      }
    })
  }

  buildUrl() {
    let url = this.data.get('url') + '?template=' + this.data.get('template')
    return url
  }
}
