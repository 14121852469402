import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ 'checkbox' ]

  toggle(e) {
    const checked = e.currentTarget.checked;
    this.checkboxTargets.forEach( (target) => { target.checked = checked } )
  }
}
