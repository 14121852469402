import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ 'form', 'submitButton' ]

  submit(e) {
    e.preventDefault()
    this.formTarget.action = e.currentTarget.dataset.url
    this.submitButtonTarget.click()
  }
}
