import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ 'graph', 'currentInterval', 'progressBar', 'toggleButton' ]

  connect() {
    this.dataset = JSON.parse(this.graphTarget.dataset.animatedDataset)
    this.chart = Chartkick.charts[this.graphTarget.dataset.chartId]
    this.refreshInterval = this.graphTarget.dataset.refreshInterval || 100
    this.totalIntervals = this.dataset.length
    this.currentInterval = 0
  }

  disconnect() {
    this.stopAnimating();
  }

  startAnimating() {
    this.animating = setInterval(this.redrawGraph.bind(this), this.refreshInterval);
  }

  stopAnimating() {
    clearInterval(this.animating)
    this.animating = null
  }

  toggleAnimation(e) {
    e.preventDefault()

    if ( this.animating == null ) {
      this.startAnimating()
      this.toggleButtons()
    } else {
      this.stopAnimating()
      this.toggleButtons()
    }
  }

  toggleButtons() {
    this.toggleButtonTarget.firstChild.classList.toggle('fa-play')
    this.toggleButtonTarget.firstChild.classList.toggle('fa-pause')
  }

  redrawGraph() {
    let current_dataset = this.dataset.shift()

    this.updateProgress(current_dataset[0])
    this.chart.updateData(current_dataset[1])
    this.chart.redraw()
    this.dataset.push(current_dataset)
  }

  updateProgress(label) {
    if ( this.currentInterval < this.totalIntervals ) {
      this.currentInterval += 1
    } else {
      this.currentInterval = 0
    }
    this.currentIntervalTarget.innerHTML = label
    this.progressBarTarget.style.width = (this.currentInterval / this.totalIntervals) * 100 + '%'
  }
}
