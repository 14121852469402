import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ 'graph', 'record', 'filter', 'formattingOptions' ]

  connect() {
    this.drawGraph()
  }

  toggle(e) {
    e.preventDefault()
    this.selectRecord(e)
    this.drawGraph()
  }

  filterChanged(e) {
    e.preventDefault()
    this.drawGraph()
  }

  drawGraph() {
    let chart = Chartkick.charts[this.graphTarget.dataset.chartId]
    let ids = this.recordTargets
                    .filter(record => record.dataset.selected == 'true' )
                    .map(record => record.dataset.recordValue)

    if (ids.length == 0) {
      chart.updateData([])
    } else {
      this.fetchData(chart, ids)
    }
  }

  fetchData(chart, ids) {
    let params = []
    let recordParam = this.graphTarget.dataset.recordParam
    ids.map(id => params.push(recordParam + '%5B%5D=' + id))

    this.filterTargets.map(target =>  {
      let param = target.dataset.filterParam + "="
      if (target.nodeName == 'SELECT') {
        params.push(param + target.options[target.selectedIndex].value)
      } else {
        params.push(param + target.value)
      }
    })

    if (this.hasFormattingOptionsTarget) { chart.setOptions(this.chartOptions()) }
    chart.dataSource = this.graphTarget.dataset.path + '?' + params.join('&')
    chart.refreshData()
  }

  selectRecord(e) {
    let record = e.currentTarget.closest('.dynamic-graph-record')

    if ( record.dataset.selected == 'true' ) {
      record.dataset.selected = false
      e.currentTarget.classList.remove('active', 'btn-secondary')
    } else {
      record.dataset.selected = true
      e.currentTarget.classList.add('active', 'btn-secondary')
    }
  }

  chartOptions() {
    let style = ''
    let options = {
      defaultFormatting: {
        scales: {}
      },
      ranking: {
        scales: { yAxes: [{ ticks: { min: 1, reverse: true } }] }
      },
      standard_deviation: {
        scales: { yAxes: [{ ticks: { min: 0, max: 15 } }] }
      },
      coverage: {
        tooltips: {
          callbacks: {
            label: function(tooltipItem, data) {
              var percent = data['datasets'][tooltipItem['datasetIndex']]['data'][tooltipItem['index']];
              var label = data.datasets[tooltipItem.datasetIndex].label || '';
              if (label) { label += ': ' }

              return label + (percent * 100).toFixed(0) + '%'
            }
          }
        },
        scales: {
          yAxes: [
            {
              ticks: {
                min: 0,
                callback: function (value) {
                  return (value / this.max * 100).toFixed(0) + '%'
                },
              }
            }
          ]
        }
      }
    }

    style = this.formattingOptionsTarget.options[this.formattingOptionsTarget.selectedIndex].dataset.graphFormat || 'defaultFormatting'
    return { library: options[style] }
  }
}
