import { Controller } from '@hotwired/stimulus'
import ApexCharts from 'apexcharts'
import { _ } from 'lodash'

export default class extends Controller {
  static targets = [ 'chartContainer', 'series' ]

  connect(){
    this.element['apex_chart'] = this;
    this.chart = new ApexCharts(this.chartContainerTarget, this.chartOptions());
    this.chart.render();
    this.renderInitialChart()
  }

  disconnect(){
    this.chart.destroy
  }

  updateData(data) {
    this.chart.updateSeries(data)
  }

  renderInitialChart() {
    if (this.element.dataset.chartData) {
      this.updateData(JSON.parse(this.element.dataset.chartData))
    } else if (this.element.dataset.chartUrl) {
      fetch(this.element.dataset.chartUrl, {
        method: 'GET'
      })
      .then(response => response.json())
      .then(json => {
        this.seriesTargets.forEach(target => {
          let series = _.find(json, { identifier: target.dataset.chartSeriesId });
          if (series) {
            series.name = target.dataset.chartSeriesName
            series.type = target.dataset.chartType
            target.apex_series.renderActive();
          }
        })
        this.updateData(json);
      })
    }
  }

  findSeries(name) {
    return this.chart.series.getSeriesByName(name)
  }

  isSeriesVisible(series) {
    return !series.classList.contains('apexcharts-series-collapsed')
  }

  connectSeries(e) {
    let series = this.findSeries(e.srcElement.dataset.chartSeriesName)
    if (series) {
      if (this.isSeriesVisible(series)) {
        e.srcElement.apex_series.renderActive();
      } else {
        e.srcElement.apex_series.renderInactive();
      }
    }
  }

  addSeries(e) {
    const seriesName = e.srcElement.dataset.chartSeriesName;

    if (this.findSeries(seriesName)) {
      this.chart.showSeries(seriesName);
    } else {
      fetch(e.srcElement.dataset.chartUrl, {
        method: 'GET'
      })
      .then(response => response.json())
      .then(json => {
        let series = json[0]
        series.name = e.srcElement.dataset.chartSeriesName
        series.type = e.srcElement.dataset.chartType
        this.chart.appendSeries(series);
      })
    }

    // Potentially to do but appears to be slow
    // this.seriesTargets.forEach(target => {
    //   if (target.dataset.chartSeriesName == seriesName) {
    //     target.apex_series.renderActive();
    //   }
    // })
  } 

  removeSeries(e) {
    this.chart.hideSeries(e.srcElement.dataset.chartSeriesName)

    // As above, potentially to do but appears to be slow
    // this.seriesTargets.forEach(target => {
    //   if (target.dataset.chartSeriesName == e.srcElement.dataset.chartSeriesName) {
    //     target.apex_series.renderInactive();
    //   }
    // })
  }

  defaultOptions() {
    return {
      chart: {
        toolbar: {
          tools: { 
            download: false
          }
        }
      },
      series: [],
      noData: {
        text: 'Loading...'
      },
      stroke: {
        curve: 'smooth',
        width: 3,
      },
      xaxis: {
        labels: {
          datetimeFormatter: {
            year: 'yyyy',
            month: 'MMM \'yy',
            day: 'M/d/yyyy',
            hour: 'HH:mm'
          }
        }
      },
      tooltip: {
        x: {
          format: 'M/d/yyyy'
        }
      }
    }
  }

  chartOptions() {
    let options = this.defaultOptions();
    if (this.element.dataset.chartOptions) { _.merge(options, JSON.parse(this.element.dataset.chartOptions)); }
    return options
  }
}